import React, { useState } from 'react';
import {
  Container, Row, Col,
} from 'reactstrap';
import { Link } from 'gatsby';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import styles from '../../components/BlogTeaser/BlogTeaser.module.scss';
import image1 from '../../images/SB296_Schubert.png';

const AktuellInhaltPage = () => (
  <Layout headerWhite>
    <SEO title="Aktuelles" />
    <Section>
      <Container>
        <Headline subtitle="Praxis für Orthopädie">VFL Magazin Anzeige</Headline>
        <Row className="justify-content-md-center">
            <Col xs="12" md="10">
            <img src={image1} style={{margin: '0 auto 1.45rem', display: 'block' }} alt="Kostka" />
            </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default AktuellInhaltPage;
